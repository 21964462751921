import { call, put, all, takeLatest } from 'redux-saga/effects';
import { ActivityResponse, ISaveSearchActivityPayload, SearchSuggestionResponse, types } from './types';
import { activityUrl, getSuggestionsUrl } from '../../configurations/api/url';
import { httpRequest } from '../types';
import { api } from '../../configurations/api';
import {
    getMyActivitiesSuccess,
    getMyActivitiesFail,
    saveSearchActivity,
    saveSearchActivitFail,
    getSearchSuggestionsSuccess,
    getSearchSuggestionsFail,
    hideMyActivitySuccess,
    hideMyActivityFail,
} from './actions';
import { parseGenericCollection, parseSingleActivity, parseSingleSuggestion } from '../../utils/responseProcessor';
import { getCacheByType, getCacheDuration, isUseCacheEnabled } from '../cache/saga';
import { CACHE_TYPE, CacheValue } from '../cache/types';
import { destroyOneCache, setCache } from '../cache/action';
import { IGenericDocumentModifier } from '../../types/global/helper';
import { Action } from 'redux';
import { SentryCapture } from '../../analytics/Sentry';
import { isEmpty } from '../../utils/lodash';

function* hideMyActivity({ payload }: { payload: IGenericDocumentModifier }): any {
    try {
        const response = yield call(
            api,
            `${activityUrl}/${payload.documentId}`,
            httpRequest.PATCH,
            payload.payload,
            0,
            0,
        );
        const { data }: { data: ActivityResponse } = response.data;
        yield put(hideMyActivitySuccess(data));
        yield put(destroyOneCache({ cacheType: CACHE_TYPE.ACTIVITY }));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(hideMyActivityFail(error));
    }
}
function* getMyActivities(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.ACTIVITY);
    if (
        cache &&
        cache.key &&
        !isEmpty(cache.value) &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration // To Temporarily fetch from server directly add `&& false` here to override cache
    ) {
        initialResult = cache.value;
    }

    if (initialResult && initialResult.length > 0 && defaultUseCache) {
        yield put(getMyActivitiesSuccess(initialResult));
    } else {
        try {
            const response = yield call(api, activityUrl, httpRequest.GET, null, 0, 0);
            const { data } = response.data;
            const parsedActivitied = parseGenericCollection(data, parseSingleActivity);
            yield put(getMyActivitiesSuccess(parsedActivitied));
            yield put(
                setCache({ key: Date.now(), value: parsedActivitied, type: CACHE_TYPE.ACTIVITY, isUnique: true }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getMyActivitiesFail(error));
        }
    }
}

function* saveActivity({ payload }: { payload: ISaveSearchActivityPayload }): any {
    try {
        const response = yield call(api, activityUrl, httpRequest.POST, payload, 0, 0);
        const { data } = response;
        yield put(saveSearchActivity(data));
    } catch (error: any) {
        SentryCapture(error, 'error');
        yield put(saveSearchActivitFail(error));
    }
}

function* getSearchSuggestions(): any {
    const defaultUseCache = yield* isUseCacheEnabled();
    const defaultCacheDuration = yield* getCacheDuration();
    let initialResult: any = null;
    const cache: CacheValue = yield* getCacheByType(CACHE_TYPE.SEARCH_SUGGESTION);
    if (
        cache &&
        cache.key &&
        !isEmpty(cache.value) &&
        ((Date.now() - Number(cache.key)) as unknown as number) < defaultCacheDuration
    ) {
        initialResult = cache.value;
    }

    if (initialResult && defaultUseCache) {
        yield put(getSearchSuggestionsSuccess(initialResult));
    } else {
        try {
            const response: SearchSuggestionResponse = yield call(api, getSuggestionsUrl, httpRequest.GET, null, 0, 0);
            const { data } = response;
            const parseSuggestion = parseGenericCollection(data, parseSingleSuggestion);
            yield put(getSearchSuggestionsSuccess(parseSuggestion));
            yield put(
                setCache({
                    key: Date.now(),
                    value: parseSuggestion,
                    type: CACHE_TYPE.SEARCH_SUGGESTION,
                    isUnique: true,
                }),
            );
        } catch (error: any) {
            SentryCapture(error, 'error');
            yield put(getSearchSuggestionsFail(error));
        }
    }
}

/** Watchers */

interface TaskAction extends Action {
    payload: any;
}

function* getMyActivitiesWatcher() {
    yield takeLatest(types.GET_MY_ACTIVITIES, getMyActivities);
}

function* saveActivityWatcher() {
    yield takeLatest<any>(types.SAVE_ACTIVITY, saveActivity);
}

function* getSearchSuggestionsWatcher() {
    yield takeLatest(types.GET_SEARCH_SUGGESTIONS, getSearchSuggestions);
}

function* hideActivityWatcher() {
    yield takeLatest<TaskAction>(types.HIDE_MY_ACTIVITY, hideMyActivity);
}

export default function* accountSaga() {
    yield all([getMyActivitiesWatcher(), saveActivityWatcher(), getSearchSuggestionsWatcher(), hideActivityWatcher()]);
}
