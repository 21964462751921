import produce from 'immer';
import {
    IGetJuryCampaignSubmissions,
    IJuryDuties,
    IJuryInitialState,
    IScoreSubmissionResponse,
    ISubmissionResultsForContest,
    types,
} from './types';
import { ITalentData, types as talentTypes } from '../talent/types';

const initialState: IJuryInitialState = {
    campaigns: [],
    completedCampaignSubmissions: [],
    completedCampaignSubmissionCount: 0,
    incompleteCampaignSubmisisons: [],
    incompleteCampaignSubmissionCount: 0,
    totalCampaignSubmissionCount: 0,
    campaignSubmisisons: [],
    errorMsg: null,
    loading: false,
    invites: [],
    currentSubmissionPage: 0,
    gettingCampaignSubmissions: false,
    selectedJuries: [],
    juryMessage: '',
    searchResults: [],
    searching: false,
    scoring: false,
};

const reducer = (state = initialState, action: any) => {
    const { type, payload } = action;
    switch (type) {
        case types.ACCEPT_JURY_INVITE:
        case types.DELETE_JURY:
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES:
        case types.INVITE_JURY:
        case types.GET_JURY_CAMPAIGNS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = true;
            });
        case types.GET_CAMPAIGN_SUBMISSIONS:
        case types.GET_COMPLETED_CAMPAIGN_SUBMISSIONS:
        case types.GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS:
            return produce(state, (draftState: typeof initialState) => {
                const { page, skipRefresh } = payload as IGetJuryCampaignSubmissions;
                draftState.gettingCampaignSubmissions = true;
                draftState.currentSubmissionPage = page as number;
                if (page < 2 && !skipRefresh) {
                    draftState.campaignSubmisisons = [];
                }

                if (page > 1) {
                    draftState.loadingMoreJurySubmissions = true;
                }
            });
        case types.GET_JURY_CAMPAIGNS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.campaigns = payload;
            });
        case types.GET_COMPLETED_CAMPAIGN_SUBMISSIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { submissions, limit, page, total_count } = payload as ISubmissionResultsForContest;
                const LIMIT_TO_USE = limit ?? 10; // TODO get this value from config
                if (page < 2) {
                    draftState.completedCampaignSubmissions = submissions;
                    draftState.completedCampaignSubmissionCount = total_count;
                } else {
                    draftState.completedCampaignSubmissions = [
                        ...draftState.completedCampaignSubmissions,
                        ...submissions,
                    ];
                    draftState.completedCampaignSubmissionCount = total_count;
                }
                draftState.currentSubmissionPage = page as number;
                draftState.canLoadMoreJurySubmissions = submissions.length >= LIMIT_TO_USE;
                if (submissions.length < LIMIT_TO_USE) {
                    draftState.currentSubmissionPage = 0;
                }
                draftState.gettingCampaignSubmissions = false;
                draftState.loadingMoreJurySubmissions = false;
                if (page > 1) {
                    draftState.loadingMoreJurySubmissions = false;
                }
            });
        case types.GET_INCOMPLETE_CAMPAIGN_SUBMISSIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { submissions, limit, page, total_count } = payload as ISubmissionResultsForContest;
                const LIMIT_TO_USE = limit ?? 10; // TODO get this value from config
                if (page < 2) {
                    draftState.incompleteCampaignSubmisisons = submissions;
                    draftState.incompleteCampaignSubmissionCount = total_count;
                } else {
                    draftState.incompleteCampaignSubmisisons = [
                        ...draftState.incompleteCampaignSubmisisons,
                        ...submissions,
                    ];
                    draftState.incompleteCampaignSubmissionCount = total_count;
                }
                draftState.currentSubmissionPage = page as number;
                draftState.canLoadMoreJurySubmissions = submissions.length >= LIMIT_TO_USE;

                if (submissions.length < LIMIT_TO_USE) {
                    draftState.currentSubmissionPage = 0;
                }
                draftState.gettingCampaignSubmissions = false;
                if (page > 1) {
                    draftState.loadingMoreJurySubmissions = false;
                }
            });
        case types.GET_CAMPAIGN_SUBMISSIONS_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { submissions, limit, page, total_count } = payload as ISubmissionResultsForContest;
                const LIMIT_TO_USE = limit ?? 10; // TODO get this value from config
                if (page < 2) {
                    draftState.campaignSubmisisons = submissions;
                    draftState.totalCampaignSubmissionCount = total_count;
                } else {
                    draftState.campaignSubmisisons = [...draftState.campaignSubmisisons, ...submissions];
                    draftState.totalCampaignSubmissionCount = total_count;
                }
                draftState.currentSubmissionPage = page as number;
                draftState.canLoadMoreJurySubmissions = submissions.length >= LIMIT_TO_USE;
                if (submissions.length < LIMIT_TO_USE) {
                    draftState.currentSubmissionPage = 0;
                }
                draftState.gettingCampaignSubmissions = false;
                if (page > 1) {
                    draftState.loadingMoreJurySubmissions = false;
                }
            });
        case talentTypes.SAVE_TALENT_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { talent } = payload as ITalentData;
                const submissionIndex = draftState.campaignSubmisisons.findIndex(
                    //@ts-ignore TODO: FIX this
                    ({ created_by }) => !!created_by && created_by._id === talent,
                );
                if (submissionIndex > -1) {
                    const currentSubmission = draftState.campaignSubmisisons[submissionIndex];
                    currentSubmission.created_by = {
                        //@ts-ignore TODO: FIX this
                        ...currentSubmission.created_by,
                        talent_saved: true,
                        saved_talent: [payload],
                    };
                    draftState.campaignSubmisisons[submissionIndex] = currentSubmission;
                }
                draftState.loading = false;
            });
        case types.ACCEPT_JURY_INVITE_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.juryMessage = payload;
            });
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
            });
        case types.INVITE_JURY_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
            });
        case types.SCORE_SUBMISSION:
            return produce(state, (draftState: typeof initialState) => {
                draftState.scoring = true;
            });
        case types.SCORE_SUBMISSION_SUCCESS:
            return produce(state, (draftState: typeof initialState) => {
                const { submission, match } = payload as IScoreSubmissionResponse;
                draftState.scoring = false;
                const index = draftState.campaignSubmisisons.findIndex(e => e._id === submission._id);
                /** Replace the submission with the scored version */
                draftState.campaignSubmisisons[index] = {
                    ...submission,
                    created_by: draftState.campaignSubmisisons[index]?.created_by,
                };
                const submissionIndex = draftState.campaignSubmisisons.findIndex(sub => sub._id === submission._id);
                const completedIndex = draftState.completedCampaignSubmissions.findIndex(
                    sub => sub._id === submission._id,
                );
                const incompleteIndex = draftState.incompleteCampaignSubmisisons.findIndex(
                    sub => sub._id === submission._id,
                );

                const searchIndex = draftState.searchResults.findIndex(sub => sub._id === submission._id);

                if (searchIndex > -1) {
                    draftState.searchResults[searchIndex] = submission;
                }

                if (match === 'all') {
                    if (submissionIndex > -1) {
                        draftState.campaignSubmisisons[submissionIndex] = submission;
                    }
                    if (completedIndex > -1) {
                        draftState.completedCampaignSubmissions[completedIndex] = submission;
                    } else {
                        draftState.completedCampaignSubmissions.push(submission);
                        draftState.completedCampaignSubmissionCount += 1;
                    }
                    if (incompleteIndex > -1) {
                        draftState.incompleteCampaignSubmisisons = draftState.incompleteCampaignSubmisisons.filter(
                            sub => sub._id !== submission._id,
                        );
                        draftState.incompleteCampaignSubmissionCount -= 1;
                    }
                }

                if (match === 'judged') {
                    if (completedIndex > -1) {
                        draftState.completedCampaignSubmissions[completedIndex] = submission;
                    } else {
                        draftState.completedCampaignSubmissions.push(submission);
                        draftState.completedCampaignSubmissionCount += 1;
                    }
                }

                if (match === 'not_judged') {
                    if (incompleteIndex > -1) {
                        draftState.incompleteCampaignSubmisisons = draftState.incompleteCampaignSubmisisons.filter(
                            sub => sub._id !== submission._id,
                        );
                        draftState.incompleteCampaignSubmissionCount -= 1;
                        draftState.completedCampaignSubmissionCount += 1;
                    }
                    if (completedIndex > -1) {
                        draftState.completedCampaignSubmissions[completedIndex] = submission;
                    } else {
                        draftState.completedCampaignSubmissions.push(submission);
                    }
                }
            });
        case types.ACCEPT_JURY_INVITE_FAIL:
        case types.DISTRIBUTE_SUBMISSIONS_TO_JURIES_FAIL:
        case types.GET_CAMPAIGN_SUBMISSIONS_FAIL:
        case types.GET_JURY_CAMPAIGNS_FAIL:
        case types.SCORE_SUBMISSION_FAIL:
            return produce(state, (draftState: typeof initialState) => {
                draftState.loading = false;
                draftState.scoring = false;
                draftState.gettingCampaignSubmissions = false;
                draftState.errorMsg = payload;
                if (typeof payload === 'string') {
                    draftState.juryMessage = payload;
                }
            });
        case types.GET_JURORS_FOR_CONTEST:
            return produce(state, draft => {
                draft.gettingJurorForContest = true;
            });
        case types.GET_JURORS_FOR_CONTEST_SUCCESS:
            return produce(state, draft => {
                draft.gettingJurorForContest = false;
                draft.selectedJuries = payload;
            });
        case types.GET_JURORS_FOR_CONTEST_FAIL:
            return produce(state, draft => {
                draft.gettingJurorForContest = false;
                draft.errorMsg = payload;
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR:
            return produce(state, draft => {
                draft.assigningJurorSubmission = true;
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR_SUCCESS:
            return produce(state, draft => {
                draft.assigningJurorSubmission = false;
                const { _id } = payload as IJuryDuties;
                const juryIndex = draft.selectedJuries?.findIndex(jury => jury._id === _id);
                if (juryIndex && juryIndex > -1) {
                    draft.selectedJuries[juryIndex] = payload;
                }
            });
        case types.ASSIGN_SUBMISSION_TO_JUROR_FAIL:
            return produce(state, draft => {
                draft.assigningJurorSubmission = false;
                draft.errorMsg = payload;
            });
        case types.GET_SCORE_BOARD:
            return produce(state, draft => {
                draft.gettingScoreBoard = true;
            });
        case types.GET_SCORE_BOARD_SUCCESS:
            return produce(state, draft => {
                draft.gettingScoreBoard = false;
                draft.scoreBoard = payload;
            });
        case types.GET_SCORE_BOARD_FAIL:
            return produce(state, draft => {
                draft.gettingScoreBoard = false;
                draft.errorMsg = payload;
            });
        case types.SEARCH_SUBMISSIONS:
            return produce(state, draft => {
                draft.searching = true;
            });
        case types.SEARCH_SUBMISSIONS_SUCCESS:
            return produce(state, draft => {
                const { submissions, limit, page, total_count, match } = payload as ISubmissionResultsForContest;
                const LIMIT_TO_USE = limit ?? 10; // TODO get this value from config
                draft.currentSubmissionPage = page as number;
                draft.searching = false;
                draft.searchResults = submissions;
                draft.canLoadMoreJurySubmissions = submissions.length >= LIMIT_TO_USE;
                if (submissions.length < LIMIT_TO_USE) {
                    draft.currentSubmissionPage = 0;
                }

                if (match === 'all') {
                    draft.totalCampaignSubmissionCount = total_count;
                }

                if (match === 'judged') {
                    draft.completedCampaignSubmissionCount = total_count;
                }

                if (match === 'not_judged') {
                    draft.incompleteCampaignSubmissionCount = total_count;
                }
            });
        case types.SEARCH_SUBMISSIONS_FAIL:
            return produce(state, draft => {
                draft.searching = true;
            });
        default:
            return state;
    }
};

export { reducer };
